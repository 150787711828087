<template>
  <div>
    <div class="backgroundSWV"></div>
    <div>
      <v-card outlined>
        <v-card-title class="titleLarge"
          >Stellenwertverständnis - Kompetenzliste</v-card-title
        >
      </v-card>
      <br />
      <br />
    </div>

    <div v-for="(n, index) in tables.length" :key="n">
      <v-card>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr style="text-align: center">
                <td colspan="3">
                  {{ tables[index].title }}
                  <div v-if="index === 0" style="text-align: center">
                    (Hinweis: Alle Angaben auf dieser Seite werden automatisch
                    gespeichert)
                  </div>
                </td>
              </tr>
              <tr>
                <td><strong>Kompetenz: Ich kann...</strong></td>
                <td width="140px" class="no-wrap" style="text-align: center">
                  <strong>unsicher - sicher</strong>
                </td>
                <td width="35%">
                  <strong>Meine Kommentare</strong>
                </td>
              </tr>
              <tr v-for="(m, index1) in tables[index].checks.length" :key="m">
                <td>
                  {{ tables[index].checks[index1].description }}
                </td>
                <td>
                  <v-radio-group
                    dense
                    row
                    v-model="tables[index].checks[index1].level"
                    @change="saveState"
                  >
                    <v-radio
                      v-for="o in 4"
                      :key="o"
                      :value="o"
                      style="margin-right: -7px; margin-left: 0px;  justify-content: center;"
                    ></v-radio>
                  </v-radio-group>
                </td>
                <td>
                  <v-textarea
                    full-width
                    auto-grow
                    rows="0"
                    label="Kommentar"
                    @input="saveState"
                    v-model="tables[index].checks[index1].comment"
                    data-matomo-unmask
                  ></v-textarea>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <br />
      <br />
    </div>

    <v-btn x-large class="white--text" color="#477B7E" to="/home" style="float: right">
      <v-icon>mdi-arrow-left</v-icon>
      Zurück zur Themenübersicht
    </v-btn>

    <AppBottomNavSWV back="/stellenwertverstaendnis/diagnose-und-foerderung" />
  </div>
</template>

<script>
import AppBottomNavSWV from "@/common/AppBottomNavSWV";
export default {
  components: {
    AppBottomNavSWV,
  },
  mounted: function() {
    this.restoreState();
  },
  data() {
    return {
      tables: [
        {
          title: "Stellenwertverständnis: Bündeln und Entbündeln",
          checks: [
            {
              description:
                "… das Prinzip der fortgesetzten Bündelung allgemein erklären.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… das Bündeln und Entbündeln grundschulgemäß anhand geeigneter Materialien erklären.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… erklären, weshalb das Prinzip der fortgesetzten Bündelung für den gesamten Mathematikunterricht von großer Bedeutung ist.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… in anderen Stellenwertsystemen Bündelungen und Entbündelungen vornehmen und anhand dessen Probleme von Kindern beim Erlernen des Bündelungsprinzips antizipieren.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… benennen, was Lernende in Bezug auf das Bündeln und Entbündeln wissen sollen.",
              level: 0,
              comment: "",
            },
          ],
        },
        {
          title: "Stellenwertverständnis: Stellenwert und Zahlenwert",
          checks: [
            {
              description:
                "… erklären, was mit dem Prinzip des Stellenwerts gemeint ist.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… erklären, was mit dem Prinzip des Zahlenwerts gemeint ist.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… die Bedeutung des Zusammenspiels dieser beiden Prinzipien für das dekadische Stellenwertsystem herausstellen.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… am Beispiel zeigen, warum die Einsicht in die Zerlegbarkeit von Zahlen zum Verständnis der Konventionen wichtig ist.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… zeigen, warum der Ziffer Null bei den Stellen- und Zahlenwerten eine besondere Rolle zukommt.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… Zahlen in anderen Stellenwertsystemen ihrer Größe nach ordnen und anhand dessen Probleme von Kindern beim Erlernen des Zahlsystems antizipieren.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… benennen was Lernende in Bezug zum Stellen- und Zahlenwert wissen sollen.",
              level: 0,
              comment: "",
            },
          ],
        },
        {
          title: "Stellenwertverständnis: Darstellungen vernetzen",
          checks: [
            {
              description:
                "… die verschiedenen Darstellungsweisen benennen und Beispiele für diese angeben.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… erklären, was mit einem Darstellungswechsel gemeint ist und dies grundschulgemäß anhand geeigneter Beispiele verdeutlichen.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… benennen, warum das Lesen, Schreiben und Sprechen von Zahlwörtern für die Lernenden eine Lernhürden bedeuten kann.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… begründen, warum sichere Darstellungswechsel als Indikator für ein tragfähiges Stellenwertverständnis fungieren können.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… Zahldarstellungen in anderen Stellenwertsystemen nach Gemeinsamkeiten gruppieren und anhand dessen Herausforderungen von Kindern beim Vernetzen von Darstellungen antizipieren.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… benennen, was Lernende in Bezug auf das Vernetzen von Darstellungen wissen sollen.",
              level: 0,
              comment: "",
            },
          ],
        },
        {
          title: "Stellenwertverständnis: Diagnose und Förderung",
          checks: [
            {
              description:
                "… die drei zentralen Hintergrundfacetten des Stellenwertverständnisses nennen und erklären, warum dieses Hintergrundwissen für die Diagnose und Förderung von Lernenden wichtig ist.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… typische Fehler im Bereich des Stellenwertverständnis nachvollziehen und nach Gemeinsamkeiten ordnen.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… bereits vorhandene Kompetenzen im Bereich des Stellenwertverständnisses anhand von Kinderdokumenten erkennen und beschreiben.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… Fehler im Bereich des Stellenwertverständnisses anhand von Kinderdokumenten erkennen und beschreiben.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… Ursachen für Fehler im Bereich des Stellenwertverständnisses anhand von Kinderdokumenten begründet ableiten.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… passgenaue Diagnoseaufgaben auswählen und diese Auswahl auch begründen.",
              level: 0,
              comment: "",
            },
          ],
        },
      ],
    };
  },
  methods: {
    restoreState: function() {
      const storedId = "CheckSWV";

      const restoredState = this.$store.state.falediaState
        ? this.$store.state.falediaState[storedId]
        : false;

      if (restoredState) {
        this.tables = restoredState.data.tables;
      }
      if (!this.$store.hasModule(["nested", storedId])) {
        this.$store.registerModule(["nested", storedId], {
          namespaced: true,
          state: {
            data: {},
          },
          mutations: {
            data(state, payload) {
              state.data = {
                tables: payload.tables,
              };
            },
          },
        });
        this.saveStateLocal();
      }
    },

    saveStateLocal: function() {
      const storedId = "CheckSWV";
      this.$store.commit("nested/" + storedId + "/data", { ...this._data });
    },

    saveState: function() {
      this.saveStateLocal();
      //send data to server
      const token = localStorage.getItem("jwt");
      try {
        this.$http
          .post("/user/state", this.$store.state.nested, {
            headers: { Authorization: token },
          })
          .then((response) => {
            localStorage.setItem("jwt", response.data.token);
            this.$store.commit("falediaState", this.$store.state.nested);
          })
          .catch(() => {
            //if there is an error, we do not save the state
          });
      } catch (err) {
        //if there is an error, we do not save the state
      }
    },
  },
};
</script>

<style scoped>
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}
#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
#app .tdButton {
  position: absolute;
  right: -10px;
  bottom: -5px;
}
#app .no-wrap {
  white-space: nowrap;
  overflow: auto;
}
</style>
